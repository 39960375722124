import { FormattedMessage } from 'react-intl';
import React from 'react';
import './SubscriptionInfo.css';
import NumberFormatter from '../../../../../components/NumberFormatter/NumberFormatter';

export default function SubscriptionInfo({ plan, currentPlan, user, organizationCredits }: any) {
  return (
    <div id="subscription-infos">
      <div className="subscription-infos-wrapper">
        <div className="sub-infos-general">
          <p>{plan.name} Plan</p>
          <NumberFormatter
            value={plan.subscriptionTotal * 0.01}
            user={user}
            currency={true}
            decimals={true}
          />
        </div>
        {/* NB LICENCES */}
        <div className="licenses-tag-ctn">
          <div className={'sub-infos-nb-licenses'}>
            {currentPlan.planId !== '0' && (
              <span className={''}>
                {currentPlan.numberOfSeats}{' '}
                {currentPlan.numberOfSeats > 1 ? (
                  <FormattedMessage id="checkout.licenses" />
                ) : (
                  <FormattedMessage id="checkout.license" />
                )}
              </span>
            )}{' '}
            {plan.isUpgrade.subscription !== null &&
              (plan.numberOfSeats !== currentPlan.numberOfSeats ||
                (currentPlan.planId === '0' &&
                  plan.numberOfSeats == currentPlan.numberOfSeats)) && (
                <>
                  {currentPlan.planId !== '0' && currentPlan.numberOfSeats > 0 && (
                    <span style={{ paddingBottom: '0.1rem' }}>
                      <img
                        src="/assets/images/cv-right.svg"
                        alt="Right arrow"
                        width={'14px'}
                        height={'14px'}
                      />
                    </span>
                  )}{' '}
                  <span>
                    {plan.numberOfSeats}{' '}
                    {plan.numberOfSeats > 1 ? (
                      <FormattedMessage id="checkout.licenses" />
                    ) : (
                      <FormattedMessage id="checkout.license" />
                    )}
                  </span>
                </>
              )}
          </div>
          <span className="billed-ann-mon">
            {plan.yearly ? (
              <p>
                <FormattedMessage id="billing.billedAnnually" />
              </p>
            ) : (
              <p>
                <FormattedMessage id="billing.billedMonthly" />
              </p>
            )}
          </span>
        </div>
        {/* CREDITS */}
        <div className="">
          <span style={{ color: '#696969', fontSize: '0.9rem' }}>
            <FormattedMessage id="billing.unlimited" />{' '}
            <FormattedMessage id={'workflow.settings.b2bEmails'} />
          </span>
          <div style={{ color: '#696969', fontSize: '0.9rem' }}>
            <FormattedMessage
              id={
                plan.numberOfMonths == 12
                  ? 'billing.phoneCreditsPerYear'
                  : 'billing.phoneCreditsPerMo'
              }
              values={{
                value: <NumberFormatter value={plan.credits.phoneCredits} user={user} />,
              }}
            />
          </div>
          <div style={{ color: '#696969', fontSize: '0.9rem' }}>
            <FormattedMessage
              id={
                plan.numberOfMonths == 12
                  ? 'billing.personalEmailCreditsPerYear'
                  : 'billing.personalEmailCreditsPerMo'
              }
              values={{
                value: <NumberFormatter value={plan.credits.personalEmailCredits} user={user} />,
              }}
            />
          </div>
          <div style={{ color: '#696969', fontSize: '0.9rem' }}>
            <FormattedMessage
              id={
                plan.numberOfMonths == 12
                  ? 'billing.exportCreditsPerYear'
                  : 'billing.exportCreditsPerMo'
              }
              values={{
                value: <NumberFormatter value={plan.credits.exportCredits} user={user} />,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
