import { useEffect, useState } from 'react';
import cookie from 'react-cookies';

export default function useChristmasBanner({
  isEligibleForDiscount,
  isChristmasCouponActive,
  didNotUnsubscribeInDecember2024,
}: any) {
  const [showChristmasBanner, setShowChristmasBanner] = useState(false);

  useEffect(() => {
    const bannerCookie = cookie.load('banner_timer');
    let shouldDisplayBanner = false;

    if (bannerCookie) {
      const nextDisplayDate = new Date(bannerCookie).getTime();
      shouldDisplayBanner = nextDisplayDate <= Date.now();
    } else {
      shouldDisplayBanner = true; // No cookie = show banner
    }

    if (
      (isEligibleForDiscount || (isChristmasCouponActive && didNotUnsubscribeInDecember2024)) &&
      shouldDisplayBanner
    ) {
      setShowChristmasBanner(true);
    }
  }, [isEligibleForDiscount, isChristmasCouponActive, didNotUnsubscribeInDecember2024]); // Added missing dependencies for completeness

  const closeBanner = () => {
    const nextDisplayDate = new Date();
    nextDisplayDate.setDate(nextDisplayDate.getDate() + 7); // Hide banner for 7 days
    //@ts-expect-error
    cookie.save('banner_timer', nextDisplayDate.toISOString(), {
      path: '/',
      expires: new Date(nextDisplayDate.getTime() + 7 * 24 * 60 * 60 * 1000), // 7 days
    });
    setShowChristmasBanner(false);
  };

  return { showChristmasBanner, closeBanner };
}
