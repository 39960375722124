import React, { useContext, useEffect, useState } from 'react';
import { priceCalc } from '../../../services/utils';
import { NavLink } from 'react-router-dom';
import OrderSummary from './OrderSummary/OrderSummary';
import OldOrderSummary from './OrderSummary/OldOrderSummary';
import CheckoutForm from './CheckoutForm/CheckoutForm';
import OldCheckoutForm from './CheckoutForm/OldCheckoutForm';
import './Checkout.css';
import { Elements, useElements, useStripe, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLIC_KEY } from '../../../constants';
import { BillingContext } from '../store/billing.store';
import {
  createOrUpdateCustomer,
  subscribe,
  getCredits,
  payNow,
} from '../../../services/api/credits.api';
import { connect } from 'react-redux';
import { ModalMessage, PreLoader } from '../../../components';
import reduxActions from '../../../redux/actions';
import { FormattedMessage, useIntl } from 'react-intl';

import { SuccessModal } from './common/common.tools';
import { useEvaluatedFlags } from '../../../providers/LDProvider/LDProvider';
import CurrencyDisplay from '../../../components/CurrencyDisplay/CurrencyDisplay';

function Checkout({ plan, organizationCredits, updatePaymentToggle, resetCredit, user }: any) {
  //@ts-expect-error
  const { state, dispatch } = useContext(BillingContext);
  const [changeCardToggle, setChangeCardToggle] = useState(false);
  const [blockProceed, setBlockProceed] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const intl = useIntl();
  const flags: any = useEvaluatedFlags(['showUpdatedCheckoutPage']);

  const checkPlanId = () => {
    if (plan.planId === 'plan_1') return false;
    if (plan.planId === 'plan_2') return false;
    if (plan.planId === 'plan_3') return false;
    return true;
  };
  const googleTagManagerEvent = () => {
    if (organizationCredits && organizationCredits.plan.planId !== '0') return;

    window.googleTagManager(
      'purchase',
      plan.unitPrice
        ? priceCalc({
            price: plan.unitPrice,
            yearly: plan.yearly,
            seats: plan.qt,
            withoutDiscount: checkPlanId(),
          })
        : priceCalc({
            price: plan.pricePerSeat,
            yearly: plan.yearly,
            perYear: true,
            seats: plan.seats,
            withoutDiscount: checkPlanId(),
          }),
    );
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (blockProceed) return;
    setBlockProceed(true);
    let source = null,
      error = null;
    if (
      !organizationCredits.customer ||
      (organizationCredits.customer && !organizationCredits.customer.cardInfo) ||
      changeCardToggle
    ) {
      const cardElement = elements.getElement(CardElement);
      //@ts-expect-error
      ({ error, source } = await stripe.createSource(cardElement));

      if (error) {
        setBlockProceed(false);
        //@ts-expect-error
        return setPaymentError(error.message);
      }
    }
    if (!plan || plan.isUpdatePaymentInfo) {
      const { data, statusCode } = await createOrUpdateCustomer({
        paymentInfos: { ...state.checkoutForm, source: source && source.id },
      });
      if (statusCode !== 200) {
        setBlockProceed(false);
        //@ts-expect-error
        return setPaymentError(data.messageTranslated || data.message);
      }
      //@ts-expect-error
      return (window.location = '/billing');
    }
    let extraCredits = plan.extraCreditsToUpgrade.map((credit: any) => ({
      amount: credit.addedCredits,
      creditType: credit.creditType,
    }));

    let currentPlan = {
      ...plan,
      numberOfSeats: plan.numberOfSeats,
      numberOfMonths: plan.numberOfMonths,
      planId: plan.planId,
      isAnnual: plan.yearly,
      country: state.checkoutForm.country,
      admins: plan.admins,
      coupon: (plan && plan.discount && plan.discount.coupon) || undefined,
      extraCredits,
    };

    if (plan.invitees) {
      currentPlan.invitees = plan.invitees;
    }

    if (plan.isPrivate || plan.previousSeats !== plan.seats) {
      currentPlan.numberOfMonths = plan.numberOfMonths;
    }
    let sb_data, sb_status;
    if (plan.isPlanSuspended) {
      ({ data: sb_data, statusCode: sb_status } = await payNow({
        paymentInfos: { ...state.checkoutForm, source: source && source.id },
        admins: plan.admins,
      }));
    } else {
      ({ data: sb_data, statusCode: sb_status } = await subscribe({
        paymentInfos: { ...state.checkoutForm, source: source && source.id },
        ...currentPlan,
      }));
    }
    //@ts-expect-error
    if (sb_status === 200 && !sb_data.ok) {
      //@ts-expect-error
      let responseStripe = await stripe.handleCardPayment(sb_data.clientSecret);
      if (responseStripe.error) {
        setBlockProceed(false);

        if (responseStripe.error.code === 'payment_intent_authentication_failure') {
          //@ts-expect-error
          return setPaymentError(intl.formatMessage({ id: 'billing.3DSError' }));
        }
        return setPaymentError(responseStripe.error.message);
      } else {
        const { data, statusCode } = await createOrUpdateCustomer({
          paymentInfos: { ...state.checkoutForm, source: source && source.id },
        });
        return setSuccessModal(true);
      }
    } else if (sb_status === 200) {
      setSuccessModal(true);
    } else {
      //@ts-expect-error
      setPaymentError(sb_data.messageTranslated || sb_data.message);
      setBlockProceed(false);
    }
  };

  const handleRedirectAndRefreshCredits = () => {
    resetCredit();
    window.location.href = '/workspace';
  };

  if (loading) return <PreLoader />;

  return (
    <div
      className={`${flags['showUpdatedCheckoutPage'] ? '' : 'main-wraper checkout-main-wrapper'}`}
    >
      <section
        id={`${flags['showUpdatedCheckoutPage'] ? 'checkout-ctn' : ''}`}
        className={`${flags['showUpdatedCheckoutPage'] ? '' : 'checkout-wrapper'}`}
        style={{ paddingBottom: flags['showUpdatedCheckoutPage'] ? '' : 0 }}
      >
        {flags['showUpdatedCheckoutPage'] ? (
          // New UI checkout page
          <form onSubmit={handleSubmit} className="checkout-wrapper">
            {plan && !plan.isUpdatePaymentInfo && (
              <OrderSummary
                plan={plan}
                organizationCredits={organizationCredits}
                error={paymentError}
                user={user}
                flags={flags}
              />
            )}
            <CheckoutForm
              organizationCredits={organizationCredits}
              setChangeCardToggle={setChangeCardToggle}
              changeCardToggle={changeCardToggle}
              updatePaymentToggle={updatePaymentToggle}
              error={paymentError}
              plan={plan}
              user={user}
              blockProceed={blockProceed}
            />
          </form>
        ) : (
          // Old UI checkout page
          <form onSubmit={handleSubmit} className="row">
            <>
              <OldCheckoutForm
                organizationCredits={organizationCredits}
                setChangeCardToggle={setChangeCardToggle}
                changeCardToggle={changeCardToggle}
                updatePaymentToggle={updatePaymentToggle}
                error={paymentError}
              />
              {plan && !updatePaymentToggle && (
                <>
                  <OldOrderSummary
                    plan={plan}
                    organizationCredits={organizationCredits}
                    blockProceed={blockProceed}
                    error={paymentError}
                    user={user}
                  />
                </>
              )}
              {plan && !updatePaymentToggle && (
                <div className={'proceed-bottom-section'}>
                  <div className="inline-info proceed-btm">
                    <div className={'proceed-texts'}>
                      <div>
                        Safe & Secure checkout. <br />
                        Charge auto-renews - You can cancel anytime.
                      </div>
                    </div>
                    <button
                      type={'submit'}
                      className="btn1"
                      style={
                        blockProceed
                          ? { background: '#ccc', width: 'max-content' }
                          : { width: 'max-content' }
                      }
                    >
                      <span className="ic-lock">
                        <img src="/assets/images/ic-lock.svg" alt="" />
                      </span>
                      {plan.total ? (
                        <FormattedMessage id={'billing.proceedToPay'} />
                      ) : (
                        <FormattedMessage id={'billing.saveChanges'} />
                      )}
                      ·{' '}
                      <CurrencyDisplay
                        user={user}
                        organizationCredits={organizationCredits}
                        amount={(plan.total * 0.01).toFixed(2)}
                      />
                    </button>
                  </div>
                  {paymentError && (
                    <div
                      style={{
                        textAlign: 'right',
                        paddingRight: '19%',
                        paddingTop: 10,
                        color: 'darkred',
                      }}
                    >
                      {paymentError}
                    </div>
                  )}
                </div>
              )}
            </>
          </form>
        )}
        {successModal && (
          <>
            {organizationCredits &&
            organizationCredits.plan &&
            organizationCredits.plan.planId == '0' ? (
              <SuccessModal
                successModal={successModal}
                handleRedirectAndRefreshCredits={handleRedirectAndRefreshCredits}
                googleTagManagerEvent={googleTagManagerEvent}
              />
            ) : (
              <ModalMessage
                toggle={successModal}
                setToggle={() => {
                  handleRedirectAndRefreshCredits();
                }}
                title={<FormattedMessage id={'billing.paymentPending'} />}
                textElement={
                  <>
                    <FormattedMessage id={'billing.yourPaymentIsBeingProcessed'} />
                  </>
                }
                action={'Ok'}
                handleAction={handleRedirectAndRefreshCredits}
                forbidCancel
                googleTagManagerEvent={googleTagManagerEvent}
              />
            )}
          </>
        )}
      </section>
    </div>
  );
}

function CheckoutHeader({ children }: any) {
  const flags: any = useEvaluatedFlags(['showUpdatedCheckoutPage']);

  return (
    <header
      className="signup-header"
      style={{ padding: flags['showUpdatedCheckoutPage'] ? '' : 0 }}
    >
      <div
        className="container-fluid"
        style={{ padding: flags['showUpdatedCheckoutPage'] ? '' : 0 }}
      >
        <div className="row align-items-center">
          <div
            className="col-4 back-ui"
            style={{
              paddingLeft: flags['showUpdatedCheckoutPage'] ? '' : '4rem',
            }}
          >
            <div className="back-home-ui">
              <a href="/billing/plans" className="back-home-btn">
                <FormattedMessage id={'billing.backToHome'} />
              </a>
            </div>
          </div>

          <div
            className="col-4"
            style={{
              marginTop: flags['showUpdatedCheckoutPage'] ? '' : '1.5rem',
            }}
          >
            <div className="logo text-center">
              <NavLink className="" to="/billing">
                <img
                  className="img-fluid"
                  src="/assets/images/logo_on_white.png"
                  width="125"
                  alt="img"
                />
              </NavLink>
            </div>
          </div>

          <div style={{ marginTop: '2rem' }}>{children}</div>
        </div>
      </div>
    </header>
  );
}

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
function CheckoutWithStripe({
  plan,
  organizationCredits,
  updatePaymentToggle,
  resetCredit,
  user,
}: any) {
  const flags: any = useEvaluatedFlags(['showUpdatedCheckoutPage']);

  return (
    <Elements stripe={stripePromise}>
      {flags['showUpdatedCheckoutPage'] ? (
        <Checkout
          plan={plan}
          organizationCredits={organizationCredits}
          updatePaymentToggle={updatePaymentToggle}
          resetCredit={resetCredit}
          user={user}
        />
      ) : (
        <CheckoutHeader>
          <Checkout
            plan={plan}
            organizationCredits={organizationCredits}
            updatePaymentToggle={updatePaymentToggle}
            resetCredit={resetCredit}
            user={user}
          />
        </CheckoutHeader>
      )}
    </Elements>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    resetCredit: async () => {
      const { data } = await getCredits();
      dispatch({ type: reduxActions.GET_CREDITS, payload: data });
    },
  };
};
export default connect(null, mapDispatchToProps)(CheckoutWithStripe);
