import React, { useState, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl/lib';
import { BillingContext } from '../../../../store/billing.store';
import './SummaryBis.css';
import SummaryAddon from './SummaryAddon/SummaryAddon';
import { useEvaluatedFlags } from '../../../../../../providers/LDProvider/LDProvider';
import useChristmasDiscount from '../../../../../../services/utils/hooks/useChristmasCoupon';
import NumberFormatter from '../../../../../../components/NumberFormatter/NumberFormatter';

export default function Summary({
  setAddCreditsToggle,
  addCreditsToggle,
  selectedPlan,
  onYearlySwitch,
  onSelectPlan,
  creditsToAdd,
  onUserSelect,
  addOnsPrice,
  minSeats,
  isVersion2,
  user,
  organizationCredits,
  subscriptionType,
}: any) {
  //@ts-expect-error
  const { state, dispatch } = useContext(BillingContext);
  const creditsTypes = [
    { id: 'phoneCredits', labelId: 'billing.phoneCredits' },
    { id: 'personalEmailCredits', labelId: 'billing.directEmailCredits' },
    { id: 'exportCredits', labelId: 'billing.exportCredits' },
  ];

  const [plan, setPlan] = useState({});
  const flags: any = useEvaluatedFlags(['']);
  const { isEligibleForDiscount } = useChristmasDiscount(flags, organizationCredits);

  useEffect(() => {
    if (state.plans) {
      const plans = state.plans.filter((plan: any) => plan.planId === selectedPlan.planId);
      setPlan(plans[0]);
    }
  }, [selectedPlan.planId]);

  return (
    <section id="customize-summary" className={''}>
      <div className="summary-top-container">
        <p className={'summary'}>
          <FormattedMessage id={'billing.customizePlan.summary'} />
        </p>

        <PriceInfo
          selectedPlan={selectedPlan}
          addOnsPrice={addOnsPrice}
          onYearlySwitch={onYearlySwitch}
          user={user}
          organizationCredits={organizationCredits}
        />
      </div>

      <div className="summary-bottom-container">
        <div className="plan-ctn-top">
          <div className={'plan-ctn'}>
            <p className="plan-badge">
              <FormattedMessage id="billing.plan" />
            </p>

            <p className="plan-type">{selectedPlan.name}</p>

            <div className={'plan-dropdown dropdown'}>
              <img src="/assets/images/users.svg" alt="users icon" width={12} height={12} />

              <button>
                {selectedPlan.numberOfSeats + ' '}

                <FormattedMessage id={selectedPlan.numberOfSeats === 1 ? 'user' : 'users'} />
              </button>
            </div>
          </div>

          <p className="plan-price-only">
            {isEligibleForDiscount ? (
              <NumberFormatter
                value={Math.ceil((selectedPlan.subscriptionTotal / 100) * 0.7)}
                user={user}
                organizationCredits={organizationCredits}
                currency={true}
                decimals={true}
              />
            ) : (
              <NumberFormatter
                value={Math.ceil(selectedPlan.subscriptionTotal / 100)}
                user={user}
                organizationCredits={organizationCredits}
                currency={true}
                decimals={true}
              />
            )}
          </p>
        </div>

        <div className="plan-billing-cycle">
          <p className="billing-period">
            <FormattedMessage id="billing.billingCycle" />
          </p>

          <div className="cycle">
            {selectedPlan.yearly ? (
              <p>
                <FormattedMessage id="billing.annual" />
              </p>
            ) : (
              <p>
                <FormattedMessage id="billing.billMonthly" />
              </p>
            )}
          </div>
        </div>

        <div className={'info-checkout-section'}>
          <div>
            <h2>
              <FormattedMessage id="billing.customizePlan.creditsIncludedInYourPlan" />
            </h2>
            {selectedPlan.planId !== '0' && (
              <div className={'unlimited-b2b'}>
                <FormattedMessage id={'billing.unlimitedB2bCredits'} />
              </div>
            )}

            <div className={'credits-global-info'}>
              <div>
                <span>
                  <FormattedMessage
                    id={
                      selectedPlan.yearly
                        ? 'billing.phoneCreditsPerYear'
                        : 'billing.phoneCreditsPerMonth'
                    }
                    values={{
                      value: parseInt(selectedPlan.phoneCredits) * (selectedPlan.yearly ? 12 : 1),
                    }}
                  />
                </span>
              </div>

              <div>
                <span>
                  <FormattedMessage
                    id={
                      selectedPlan.yearly
                        ? 'billing.personalEmailCreditsPerYear'
                        : 'billing.personalEmailCreditsPerMonth'
                    }
                    values={{
                      value:
                        parseInt(selectedPlan.personalEmailCredits) *
                        (selectedPlan.yearly ? 12 : 1),
                    }}
                  />
                </span>
              </div>

              <div>
                <span>
                  <FormattedMessage
                    id={
                      selectedPlan.yearly
                        ? 'billing.exportCreditsPerYear'
                        : 'billing.exportCreditsPerMonth'
                    }
                    values={{
                      value: parseInt(selectedPlan.exportCredits) * (selectedPlan.yearly ? 12 : 1),
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* ADD ON SECTION */}

        <div className="summary-addon-section">
          {creditsTypes.map(({ id, labelId }, index) => (
            <>
              <hr />

              <SummaryAddon
                id={id}
                labelId={labelId}
                creditsToAdd={creditsToAdd}
                organizationCredits={organizationCredits}
                user={user}
                subscriptionType={subscriptionType}
              />
            </>
          ))}
        </div>
      </div>

      <button className={'plan-checkout'} onClick={onSelectPlan}>
        <FormattedMessage id={'billing.checkout'} />
      </button>
    </section>
  );
}
function PriceInfo({ selectedPlan, addOnsPrice, onYearlySwitch, organizationCredits, user }: any) {
  const flags: any = useEvaluatedFlags(['']);
  const { isEligibleForDiscount } = useChristmasDiscount(flags, organizationCredits);

  function formatPrice(price: any) {
    const priceFixed = price.toFixed(1);
    return priceFixed.endsWith('.0') ? priceFixed.slice(0, -2) : priceFixed;
  }

  if (selectedPlan.isCustom)
    return (
      <div>
        <div>
          <strong>
            <NumberFormatter
              value={Math.ceil(
                (selectedPlan.subscriptionTotal / 100 + addOnsPrice) / selectedPlan.numberOfMonths,
              )}
              user={user}
              organizationCredits={organizationCredits}
              currency={true}
              decimals={true}
            />
          </strong>{' '}
          <span>
            / <FormattedMessage id={'month'} />{' '}
          </span>
        </div>

        <p>
          <FormattedMessage id={'billing.youWillBeCharged'} />{' '}
          <NumberFormatter
            value={selectedPlan.subscriptionTotal / 100 + addOnsPrice}
            user={user}
            organizationCredits={organizationCredits}
            currency={true}
            decimals={true}
          />{' '}
          {((selectedPlan.remainingMonths && selectedPlan.remainingMonths == 12) ||
            !selectedPlan.remainingMonths) && (
            <FormattedMessage
              id={'billing.forXMonths'}
              values={{
                value: selectedPlan.numberOfMonths,
              }}
            />
          )}
        </p>
      </div>
    );

  return (
    <div className="price-info-container">
      <div className="top-container-price-info">
        {selectedPlan.yearly ? (
          <>
            {/* PER YEAR  */}

            <div className="price">
              <NumberFormatter
                value={
                  isEligibleForDiscount
                    ? Math.ceil((selectedPlan.subscriptionTotal / 100) * 0.7 + addOnsPrice)
                    : Math.ceil(selectedPlan.subscriptionTotal / 100 + addOnsPrice)
                }
                user={user}
                organizationCredits={organizationCredits}
                currency={true}
                decimals={true}
              />
            </div>

            <span className="price" style={{ marginLeft: '8px' }}>
              /
              <FormattedMessage id={'billing.customizePlan.yr'} />
            </span>
          </>
        ) : (
          <>
            {/* PER MONTH */}

            <div className="price">
              {isEligibleForDiscount ? (
                <NumberFormatter
                  value={
                    selectedPlan.yearly
                      ? Math.ceil(
                          (selectedPlan.subscriptionTotal * 0.7) /
                            (selectedPlan.remainingMonths || 12),
                        )
                      : Math.ceil(
                          (selectedPlan.subscriptionTotal * 0.7) / 100 +
                            (selectedPlan.yearly
                              ? addOnsPrice / (selectedPlan.remainingMonths || 12)
                              : addOnsPrice),
                        )
                  }
                  user={user}
                  organizationCredits={organizationCredits}
                  currency={true}
                  decimals={true}
                />
              ) : (
                <NumberFormatter
                  value={
                    selectedPlan.yearly
                      ? Math.ceil(
                          selectedPlan.subscriptionTotal / (selectedPlan.remainingMonths || 12),
                        )
                      : Math.ceil(selectedPlan.subscriptionTotal) / 100 +
                        (selectedPlan.yearly
                          ? addOnsPrice / (selectedPlan.remainingMonths || 12)
                          : addOnsPrice)
                  }
                  user={user}
                  organizationCredits={organizationCredits}
                  currency={true}
                  decimals={true}
                />
              )}
            </div>

            <span className="price" style={{ marginLeft: '8px' }}>
              /
              <FormattedMessage id={'billing.customizePlan.mo'} />
            </span>
            {'\n\n'}
          </>
        )}
      </div>

      <p className="top-container-desc">
        <FormattedMessage id="billing.customizePlan.exclVat" />
      </p>
    </div>
  );
}
