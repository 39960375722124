import { useMemo } from 'react';

const useChristmasDiscount = (flags: any, organizationCredits: any) => {
  const isChristmasCouponActive = useMemo(() => {
    return flags && flags['christmasCoupon'];
  }, [flags]);

  const hasFreePlan = useMemo(() => {
    return (
      organizationCredits && organizationCredits.plan && organizationCredits.plan.planId === '0'
    );
  }, [organizationCredits]);

  const didNotUnsubscribeInDecember2024 = useMemo(() => {
    if (!organizationCredits || !organizationCredits.lastUnsubscriptionDate) return true;

    const lastUnsubscriptionDate = new Date(organizationCredits.lastUnsubscriptionDate);
    return !(
      (lastUnsubscriptionDate.getFullYear() === 2024 && lastUnsubscriptionDate.getMonth() === 11) // 11 = December (0-based index)
    );
  }, [organizationCredits]);

  const isEligibleForDiscount = useMemo(() => {
    return isChristmasCouponActive && hasFreePlan && didNotUnsubscribeInDecember2024;
  }, [isChristmasCouponActive, hasFreePlan, didNotUnsubscribeInDecember2024]);

  return {
    isChristmasCouponActive,
    hasFreePlan,
    didNotUnsubscribeInDecember2024,
    isEligibleForDiscount,
  };
};

export default useChristmasDiscount;
