import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import './ChristmasBanner.css';

const ChristmasBanner = ({ closeBanner }: any) => {
  const navigate = useNavigate();

  return (
    <div id="christmas-banner">
      <div className="christmas-inner-banner">
        <div className="reduction-tag">
          <p>
            <FormattedMessage id="christmasCoupon.XPercentOffAllPlans" values={{ value: '30' }} />
          </p>
        </div>
        <div className="limited-time-offer">
          <FormattedMessage id="christmasCoupon.limitedTimeOffer" />
        </div>
        <button onClick={() => navigate('/billing/plans')}>
          <FormattedMessage id="christmasCoupon.getDiscount" />
        </button>
        <span className="ic-cross-banner" onClick={closeBanner}>
          <img src="/assets/images/ic-cross-white.svg" width={'16px'} height={'16px'} alt="" />
        </span>
      </div>
    </div>
  );
};

export default ChristmasBanner;
