import React, { useState } from 'react';
import './BillingCycle.css';
import { useEvaluatedFlags } from '../../../../../providers/LDProvider/LDProvider';
import { FormattedMessage } from 'react-intl';
import useChristmasDiscount from '../../../../../services/utils/hooks/useChristmasCoupon';
import FixPriceInfoFreePlan from '../../../common/CutomizePlanModal/common/FixPriceInfoFreePlan/FixPriceInfoFreePlan';

const BillingCycle = ({
  selectedPlan,
  addOnsPrice,
  onYearlySwitch,
  subscriptionType,
  organizationCredits,
  user,
  nbLicenses,
}: any) => {
  const [isActive, setIsActive] = useState(
    selectedPlan.planId === 'plan_3'
      ? 'annual'
      : subscriptionType === 'yearly'
        ? 'annual'
        : 'monthly',
  );

  const flags: any = useEvaluatedFlags(['']);
  const { isEligibleForDiscount } = useChristmasDiscount(flags, organizationCredits);

  const handleAnnualClick = () => {
    setIsActive('annual');
    onYearlySwitch('yearly');
  };

  const handleMonthlyClick = () => {
    setIsActive('monthly');
    onYearlySwitch('monthly');
  };

  return (
    <div id="billing-cycle">
      <h1>
        <FormattedMessage id="billing.customizePlan.chooseBillingCycle" />
        {isEligibleForDiscount && (
          <span className="discount-included">
            <FormattedMessage
              id="christmasCoupon.XPercentDiscountIncluded"
              values={{ value: '30' }}
            />
          </span>
        )}
      </h1>

      <div className="billing-cycle-ctn">
        <div
          className={`billing-option annually-ctn-wrapper ${isActive === 'annual' ? 'active' : ''}`}
          onClick={handleAnnualClick}
        >
          <div className="annually-ctn" style={{ position: 'relative', paddingBottom: '16px' }}>
            <div className="left">
              <p>
                <FormattedMessage id="billing.annual" />
              </p>

              <div className="price">
                {isEligibleForDiscount ? (
                  <>
                    <h2 style={{ marginBottom: 0 }}>
                      <FixPriceInfoFreePlan
                        selectedPlan={selectedPlan}
                        addOnsPrice={addOnsPrice}
                        onYearlySwitch={onYearlySwitch}
                        organizationCredits={organizationCredits}
                        user={user}
                        planType={'yearly'}
                        nbLicenses={nbLicenses}
                        style={{
                          width: '100%',
                          fontSize: '22px',
                          fontWeight: 400,
                          color: '#A5A9BB',
                          textDecoration: 'line-through',
                        }}
                      />
                    </h2>

                    <h2 style={{ marginBottom: 0 }}>
                      <FixPriceInfoFreePlan
                        selectedPlan={selectedPlan}
                        addOnsPrice={addOnsPrice}
                        onYearlySwitch={onYearlySwitch}
                        organizationCredits={organizationCredits}
                        user={user}
                        planType={'yearly'}
                        nbLicenses={nbLicenses}
                        applyDiscount={true}
                        style={{ fontSize: '22px', fontWeight: 600 }}
                      />
                    </h2>
                  </>
                ) : (
                  <h2 style={{ marginBottom: 0 }}>
                    <FixPriceInfoFreePlan
                      selectedPlan={selectedPlan}
                      addOnsPrice={addOnsPrice}
                      onYearlySwitch={onYearlySwitch}
                      organizationCredits={organizationCredits}
                      user={user}
                      planType={'yearly'}
                      nbLicenses={nbLicenses}
                    />
                  </h2>
                )}

                {isEligibleForDiscount ? (
                  <span style={{ textTransform: 'lowercase', fontWeight: 400, color: '#111833' }}>
                    /<FormattedMessage id="billing.plan.perMonth" />
                  </span>
                ) : (
                  <span style={{ textTransform: 'lowercase' }}>
                    /<FormattedMessage id="billing.customizePlan.mo" />
                  </span>
                )}
              </div>
            </div>
            {(selectedPlan.planId === 'plan_1' || selectedPlan.planId === 'plan_2') && (
              <div
                className="save-percent"
                style={{ position: 'absolute', right: '0.5rem', top: '0.75rem' }}
              >
                <p>
                  <FormattedMessage
                    id="billing.plan.saveXPercent"
                    values={{
                      value:
                        selectedPlan.planId === 'plan_1'
                          ? '25'
                          : selectedPlan.planId === 'plan_2'
                            ? '20'
                            : '0',
                    }}
                  />
                </p>
              </div>
            )}
          </div>
        </div>
        {selectedPlan.planId !== 'plan_3' && (
          <div
            className={`billing-option monthly-ctn-wrapper ${
              isActive === 'annual' ? '' : 'active-monthly'
            }`}
            onClick={handleMonthlyClick}
          >
            <div className="monthly-ctn" style={{ paddingBottom: '16px' }}>
              <div className="left">
                <p>
                  <FormattedMessage id="billing.billMonthly" />
                </p>

                <div className="price">
                  {isEligibleForDiscount ? (
                    <>
                      <h2 style={{ marginBottom: 0 }}>
                        <FixPriceInfoFreePlan
                          selectedPlan={selectedPlan}
                          addOnsPrice={addOnsPrice}
                          onYearlySwitch={onYearlySwitch}
                          organizationCredits={organizationCredits}
                          user={user}
                          planType={'monthly'}
                          nbLicenses={nbLicenses}
                          style={{
                            width: '100%',
                            fontSize: '22px',
                            fontWeight: 400,
                            color: '#A5A9BB',
                            textDecoration: 'line-through',
                          }}
                        />
                      </h2>
                      <h2 style={{ marginBottom: 0 }}>
                        <FixPriceInfoFreePlan
                          selectedPlan={selectedPlan}
                          addOnsPrice={addOnsPrice}
                          onYearlySwitch={onYearlySwitch}
                          organizationCredits={organizationCredits}
                          user={user}
                          planType={'monthly'}
                          nbLicenses={nbLicenses}
                          applyDiscount={true}
                          style={{ fontSize: '22px', fontWeight: 600 }}
                        />
                      </h2>
                    </>
                  ) : (
                    <h2 style={{ marginBottom: 0 }}>
                      <FixPriceInfoFreePlan
                        selectedPlan={selectedPlan}
                        addOnsPrice={addOnsPrice}
                        onYearlySwitch={onYearlySwitch}
                        organizationCredits={organizationCredits}
                        user={user}
                        planType={'monthly'}
                        nbLicenses={nbLicenses}
                      />
                    </h2>
                  )}
                  {isEligibleForDiscount ? (
                    <span style={{ textTransform: 'lowercase', fontWeight: 400, color: '#111833' }}>
                      /<FormattedMessage id="billing.plan.perMonth" />
                    </span>
                  ) : (
                    <span style={{ textTransform: 'lowercase' }}>
                      /<FormattedMessage id="billing.customizePlan.mo" />
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BillingCycle;
