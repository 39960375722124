import React, { useEffect, useState, useRef } from 'react';
import './SideNav.css';
import { Col, Navbar, Row, Container, Form } from 'react-bootstrap';
import { NavLink, Navigate, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useEvaluatedFlags } from '../../providers/LDProvider/LDProvider';
import useChristmasDiscount from '../../services/utils/hooks/useChristmasCoupon';

function SideNav({ navToggle, redux, isOnboarding }: any) {
  const [isPaidCustomer, setIsPaidCustomer] = useState(false);

  useEffect(() => {
    setIsPaidCustomer(
      // Legacy browsers supported
      redux.credits &&
        redux.credits.organizationCredits &&
        redux.credits.organizationCredits.plan &&
        redux.credits.organizationCredits.plan.planId !== '0',
    );
  }, []);

  const checkPermission = () => {
    const member = redux.organization.member;
    if (!member) return true;
    if (member && member.type !== 'member') return true;
  };

  return (
    <SideNavWrapper navToggle={navToggle}>
      <NavItem
        to={'/leads'}
        icon={'lead-icon'}
        page={<FormattedMessage id={'sidenav.leads'} />}
        tracking={{ 'data-tracking-id': 'sidenav.leads' }}
        credits={redux.credits}
      />

      <NavItem
        to={'/bulk-enrichment'}
        icon={'bulk-icon'}
        page={<FormattedMessage id={'sidenav.bulkEnrichment'} />}
        tracking={{ 'data-tracking-id': 'sidenav.bulkEnrichment' }}
        credits={redux.credits}
      />

      <NavItem
        to={'/workflow'}
        icon={'workflow-icon'}
        page={<FormattedMessage id={'sidenav.workflow'} />}
        tracking={{ 'data-tracking-id': 'sidenav.workflow' }}
        credits={redux.credits}
      />

      <NavItem
        to={'/workspace'}
        icon={'team-icon'}
        page={<FormattedMessage id={'sidenav.organization'} />}
        organization={redux.organization.organization}
        collapseId={'#organiztaion-collapse'}
        tracking={{ 'data-tracking-id': 'sidenav.organization' }}
        subOrganizations={
          redux.organization.subOrganizations && redux.organization.subOrganizations.organizations
        }
        credits={redux.credits}
      />

      {checkPermission() && (
        <>
          <NavItem
            to={isPaidCustomer ? '/billing/info' : '/billing/plans'} // Navigate to appropriate billing page based on customer status
            icon={'billing-icon'}
            page={<FormattedMessage id={'sidenav.billing'} />}
            collapseId={'#billing-collapse'}
            tracking={{ 'data-tracking-id': 'sidenav.billing' }}
            credits={redux.credits}
          />
        </>
      )}
      {/* Show NavItem only if onboarding is skipped */}
      {redux && redux.profile && redux.profile.nextStep && redux.profile.nextStep === -1 && (
        <NavItem
          to={'/onboarding'}
          icon={'onboarding-icon'}
          page={<FormattedMessage id={'home.onboarding'} />}
          collapseId={'#onboarding-collapse'}
          tracking={{ 'data-tracking-id': 'sidenav.onboarding' }}
          credits={redux.credits}
        />
      )}
      <NavItem
        to={'/settings'}
        icon={'setting-icon'}
        page={<FormattedMessage id={'sidenav.settings'} />}
        collapseId={'#setting-collapse'}
        tracking={{ 'data-tracking-id': 'sidenav.settings' }}
        credits={redux.credits}
      />
    </SideNavWrapper>
  );
}

// =========   side nav components  =========

function NavItem(props: any) {
  const [redirect, setRedirect] = useState(false);
  let navigate = useNavigate();
  const location = useLocation();

  const flags: any = useEvaluatedFlags(['']);
  const { isEligibleForDiscount } = useChristmasDiscount(flags, props.credits.organizationCredits);

  // Check if the current route is `/onboarding`
  const isOnboardingActive =
    location.pathname === '/onboarding' || location.pathname === '/onboarding/discover';

  return (
    <li>
      <NavLink
        to={props.to}
        className={(navData) =>
          navData.isActive
            ? `active${props.to === '/onboarding' ? ' onBoarding-home-icon-change-style' : ''}`
            : null
        }
        data-toggle="collapse"
        data-target={props.collapseId}
        {...props.tracking}
        onClick={() => {
          navigate(props.to);
          // Special navigation logic for '/leads' route
          if (props.to === '/leads' && window.location.pathname.includes('/lead/')) {
            navigate('/leads');
          }
          setRedirect(true);
        }}
      >
        <span className={'icon ' + props.icon} />

        <span className="nav-txt">{props.page}</span>

        {!isOnboardingActive &&
          (props.to === '/billing/info' || props.to === '/billing/plans') &&
          isEligibleForDiscount && (
            <div className={'discount-icon'}>
              <img width={16} height={16} src="/assets/images/icon-discount.svg" alt="" />
            </div>
          )}
      </NavLink>

      <div className="tooltip-box">
        <p>{props.page}</p>
      </div>

      {/* Redirect after navigation */}
      {redirect && <Navigate to={props.to} replace />}
    </li>
  );
}

function Collapse({ id, pages }: any) {
  return (
    <div id={id} className={'collapse '}>
      <ul className="dropdown-list nav nav-tabs">
        {pages.map((page: any, key: any) => {
          return (
            <li key={key}>
              <a data-toggle="tab" href={page.to} style={{ background: 'none', boxShadow: 'none' }}>
                {page.name}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
function SideNavWrapper({ children, navToggle }: any) {
  return (
    <Col
      md={2}
      lg={2}
      className={'sticky side-nav-container' /*+ (navToggle ? "small-navbar" : "")*/}
    >
      <div id="sideNav">
        <Navbar expand={'md'} className="navbar  navbar-light">
          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <ul className="navbar-nav custScroll ">{children}</ul>
          </div>
        </Navbar>
      </div>
    </Col>
  );
}
const mapStateToProps = (state: any) => ({
  redux: state,
});
export default connect(mapStateToProps)(SideNav);
