import { useState, useEffect } from 'react';

const useIsNotOnBillingPage = () => {
  const [isNotOnBillingPage, setIsNotOnBillingPage] = useState(
    !window.location.pathname.includes('/billing'),
  );

  useEffect(() => {
    // Update the state based on the current URL
    const updatePathStatus = () => {
      setIsNotOnBillingPage(!window.location.pathname.includes('/billing'));
    };

    // Listen for changes in the browser's history
    const handleHistoryChange = updatePathStatus;

    window.addEventListener('popstate', handleHistoryChange);

    // Override history methods to trigger updates
    const originalPushState = history.pushState;
    const originalReplaceState = history.replaceState;

    history.pushState = (...args) => {
      const result = originalPushState.apply(history, args);
      window.dispatchEvent(new Event('popstate'));
      return result;
    };

    history.replaceState = (...args) => {
      const result = originalReplaceState.apply(history, args);
      window.dispatchEvent(new Event('popstate'));
      return result;
    };

    // Clean up listeners and restore original methods
    return () => {
      window.removeEventListener('popstate', handleHistoryChange);
      history.pushState = originalPushState;
      history.replaceState = originalReplaceState;
    };
  }, []);

  return isNotOnBillingPage;
};

export default useIsNotOnBillingPage;
