import React, { useContext, useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import { actions, LeadsContext } from '../../../store/leads.store';
import './LeadsListFilter.css';
import {
  deleteLead,
  exportLeadsAsCsv,
  exportLeadsAsExcel,
  leadsFilter,
  tagLead,
  updateLead,
} from '../../../../../services/api/leads.api';
import {
  Pagination,
  Animation,
  SelectPicker,
  ModalMessage,
  Tooltip,
  PaginationInput,
  Permissions,
} from '../../../../../components';

import LeadsProperties from '../../LeadsProperties/LeadsProperties';
import { Button, OverlayTrigger } from 'react-bootstrap';
import { checkPermission } from '../../../../../services/utils';
import { checkIsPlanLimited } from '../../../../../services/utils/checkPermissions';
import { FormattedMessage, injectIntl } from 'react-intl';

import TagsList from '../../TagsList/TagsList';
import { usePermissions } from '../../../../../components/Permissions/permissions.hooks';
import { useEvaluatedFlags } from '../../../../../providers/LDProvider/LDProvider';

function LeadsListFilter({
  page,
  setPage,
  handleGoToPage,
  organizationCredit,
  profile,
  handleLoader,
  setCounter,
  handleExportLeads,
  intl,
}: any) {
  //@ts-expect-error
  const { state, dispatch } = useContext(LeadsContext);
  const [selectToggle, setSelectToggle] = useState<any>(false);
  const [deleteLeadsToggle, setDeleteLeadsModal] = useState(false);
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [checkPlanPermissions, checkMemberPermissions] = usePermissions(
    organizationCredit && organizationCredit.plan.permissions,
    profile.permissions,
    'Leads',
  );
  const flags: any = useEvaluatedFlags(['hideResultsDropdown']);

  useEffect(() => {
    if (flags['hideResultsDropdown']) {
      handleResultsPerPage(25);
    }
  }, [flags]);

  const planIsLimitedPermission = checkIsPlanLimited({
    permissions: organizationCredit && organizationCredit.plan.permissions,
    name: 'Leads',
  });
  const selectedLeads = () => {
    const count = (state.leads && state.leads.filter((lead: any) => lead.isChecked).length) || 0;
    return count == 0 ? false : count;
  };
  const handleFilter = async (sortBy: any) => {
    dispatch({
      type: actions.LEADS_FILTER,
      payload: { sortBy },
    });
    setTriggerFilter(!triggerFilter);
  };
  const handleResultsPerPage = async (value: any) => {
    dispatch({
      type: actions.LEADS_FILTER,
      payload: { resultPerPage: value, page: page },
    });
    setTriggerFilter(!triggerFilter);
  };
  const handleAddToLabel = async (label: any) => {
    if (!checkMemberPermissions('Export')) {
      return toast.error(<FormattedMessage id={'permissions.noAccess'} />);
    }

    if (!checkPlanPermissions('Export')) {
      return toast.error(<FormattedMessage id={'permissions.upgradePlan'} />);
    }
    let leads = state.leads.filter((lead: any) => lead.isChecked);
    const { data, statusCode } = await updateLead(leads, label.id);
    if (statusCode === 200) {
      setTriggerFilter(!triggerFilter);
      setSelectToggle(false);
    }
  };
  const handleDeleteLeads = async () => {
    if (!checkMemberPermissions('Export')) {
      return toast.error(<FormattedMessage id={'permissions.noAccess'} />);
    }

    if (!checkPlanPermissions('Export')) {
      return toast.error(<FormattedMessage id={'permissions.upgradePlan'} />);
    }

    let leads = state.leads.filter((lead: any) => lead.isChecked);
    let ids = leads.map((lead: any) => lead._id);
    const { data, statusCode } = await deleteLead(ids);
    if (statusCode == 200) {
      setTriggerFilter(!triggerFilter);
    }
    //@ts-expect-error
    setDeleteLeadsModal();
  };

  const AddToList = () => {
    return (
      <div id={'add-to-list'}>
        <div className="dropdown">
          <div
            className="white-button"
            style={{ marginRight: '5px' }}
            onClick={() => setSelectToggle('addToList')}
            data-tracking-id={'leads.filters-addSelectedLeadsToList'}
          >
            <img
              src="/assets/images/ic-add.svg"
              alt=""
              style={{ width: 20, height: 20, marginRight: 10, opacity: 0.6 }}
            />

            <p>
              <FormattedMessage id={'leads.addToList'} />
            </p>
          </div>

          {selectToggle == 'addToList' && (
            <Animation.SlideDown
              className={'dropdown-menu show'}
              toggle={selectToggle == 'addToList'}
              setToggle={setSelectToggle}
            >
              <ul>
                <li
                  onClick={() =>
                    handleAddToLabel({
                      name: <FormattedMessage id={'leads.defaultList'} />,
                      id: null,
                    })
                  }
                >
                  <a
                    href="#"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      margin: '10px 0',
                    }}
                  >
                    <span className="name_ui">
                      {' '}
                      <FormattedMessage id={'leads.defaultList'} />
                    </span>
                  </a>
                </li>
                {state.labels.map((label: any, i: any) => (
                  <li key={i} onClick={() => handleAddToLabel(label)}>
                    <a
                      href="#"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '10px 0',
                      }}
                    >
                      <span className="name_ui">{label.name}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </Animation.SlideDown>
          )}
        </div>
      </div>
    );
  };
  const DeleteLeadsButton = () => {
    return (
      <div
        className={'white-button '}
        style={{
          width: 'auto',
          padding: '0 10px',
          color: planIsLimitedPermission ? '#ccc' : '#555',
        }}
        //@ts-expect-error
        onClick={planIsLimitedPermission ? () => false : setDeleteLeadsModal}
        data-tracking-id={'leads.filters-deleteSelectedLeads'}
      >
        <img src="/assets/images/icon-delete.svg" alt="" />{' '}
        <span style={{ margin: '0 5px', fontWeight: '500' }}>
          <FormattedMessage id={'delete'} />
        </span>
      </div>
    );
  };

  useEffect(() => {
    dispatch({
      type: actions.LEADS_LOADING,
      payload: true,
    });
    leadsFilter({ data: { ...state.dataForm } }).then(({ data }) => {
      dispatch({
        type: actions.LEADS_LOADING,
        payload: false,
      });
      dispatch({
        type: actions.GET_LEADS,
        //@ts-expect-error
        payload: data.leads,
        //@ts-expect-error
        pages: data.pages,
      });
    });
  }, [triggerFilter]);
  const handleAssignTagToLead = async (tag: any) => {
    if (!checkMemberPermissions('Export')) {
      return toast.error(<FormattedMessage id={'permissions.noAccess'} />);
    }

    if (!checkPlanPermissions('Export')) {
      return toast.error(<FormattedMessage id={'permissions.upgradePlan'} />);
    }
    let selectedLeads = state.leads && state.leads.filter((lead: any) => lead.isChecked);
    let leadIds = selectedLeads.map((lead: any) => lead._id);
    const { statusCode } = await tagLead({
      leadIds,
      tag: tag._id,
      clientDate: new Date(),
    });
    if (statusCode == 200) {
      leadsFilter({ data: { ...state.dataForm } }).then(({ data }) => {
        let map = {};
        //@ts-expect-error
        data.leads.forEach((lead: any) => (map[lead._id] = lead));
        let leads = state.leads.map((lead: any) => {
          //@ts-expect-error
          if (map[lead._id]) lead.tags = map[lead._id].tags;
          return lead;
        });
        dispatch({
          type: actions.GET_LEADS,
          payload: leads,
          //@ts-expect-error
          pages: data.pages,
        });
      });
    }
  };
  return (
    <div className="head_block">
      {selectedLeads() ? (
        <div className="left_block" style={{ alignItems: 'center' }}>
          <span className="leads-select-tag">
            {selectedLeads() || 0} <FormattedMessage id={'leads.leadsSelected'} />
          </span>

          <DeleteLeadsButton />

          {!checkPlanPermissions('Save') || !checkMemberPermissions('Save') ? (
            <Permissions.CallToAction isPlan={!checkPlanPermissions('Save')} withoutIcon={true}>
              <AddToList />
            </Permissions.CallToAction>
          ) : (
            <AddToList />
          )}

          <TagsList
            tags={state.allTags}
            selectTag={handleAssignTagToLead}
            style={{ width: 'auto', marginLeft: 20 }}
            withoutTitle
            trackingFrom={'leads.filters'}
            maxTags={
              organizationCredit && organizationCredit.plan && organizationCredit.plan.maxTags
            }
          />
        </div>
      ) : (
        <div className="left_block" style={{ alignItems: 'center' }}>
          <div className="title-box">
            <p>
              {state.pages && state.pages.totalLeads} <FormattedMessage id={'lead(s)'} />
            </p>
          </div>

          <div className="sorting-block">
            <label>
              {' '}
              <FormattedMessage id={'filters.sortBy'} />
            </label>
            {planIsLimitedPermission ? (
              <OverlayTrigger
                key={'plan-permission'}
                placement={'top'}
                overlay={(props) =>
                  Tooltip({
                    ...props,

                    data: <FormattedMessage id={'permissions.upgradePlan'} />,
                  })
                }
              >
                <img
                  src="assets/images/lockBlack.png"
                  style={{ width: 15, marginLeft: 7 }}
                  alt=""
                />
              </OverlayTrigger>
            ) : (
              <div className="cstm-select date-sorting" data-tracking-id={'leads.filters-sortBy'}>
                <SelectPicker
                  doNotCapitalize
                  values={['descending', 'ascending']}
                  onChange={handleFilter}
                  type={'sortBy'}
                  labelsStyle={{ width: 'max-content' }}
                />
              </div>
            )}
          </div>
          {!flags.hideResultsDropdown && (
            <div
              className="sorting-block table-listing"
              data-tracking-id={'leads.filters-resultsPerPage'}
            >
              <label data-tracking-id={'leads.filters-resultsPerPage'}>
                <FormattedMessage id={'results'} />
              </label>
              {planIsLimitedPermission ? (
                <OverlayTrigger
                  key={'plan-permission'}
                  placement={'top'}
                  overlay={(props) =>
                    Tooltip({
                      ...props,
                      data: <FormattedMessage id={'permissions.upgradePlan'} />,
                    })
                  }
                >
                  <img
                    src="assets/images/lockBlack.png"
                    style={{ width: 15, marginLeft: 7 }}
                    alt=""
                  />
                </OverlayTrigger>
              ) : (
                <SelectPicker values={[10, 30, 50]} onChange={handleResultsPerPage} />
              )}
            </div>
          )}
        </div>
      )}

      <div className="right_block">
        {!selectedLeads() && (
          <div style={{ display: 'flex' }} data-tracking-id={'leads.filters-pagination'}>
            <label style={{ margin: '14px 12px 0px 12px', color: '#595F79' }}>
              <FormattedMessage id={'page'} /> :
            </label>

            <div style={{ marginTop: 5 }}>
              <PaginationInput
                pages={state.pages && state.pages.pages}
                currentPage={page}
                onChange={handleGoToPage}
                lock={planIsLimitedPermission}
              />
            </div>

            <label style={{ margin: '14px 12px 0px 12px', color: '#595F79' }}>
              <FormattedMessage
                id={'ofPages'}
                values={{ value: state.pages && state.pages.pages }}
              />
            </label>
          </div>
        )}

        <div className={'properties-and-export'}>
          <LeadsProperties organizationCredit={organizationCredit} />

          <div className="export-dropdown-ui">
            <div className={'export-btn'} data-tracking-id={'leads.filters-export'}>
              {!checkPlanPermissions('Export') || !checkMemberPermissions('Export') ? (
                <SelectPicker
                  values={[
                    {
                      name: (
                        <Permissions.CallToAction isPlan={!checkPlanPermissions('Export')}>
                          <FormattedMessage id={'exportCsv'} />
                        </Permissions.CallToAction>
                      ),
                      id: 'Export CSV',
                      trackingId: 'leads-filters-exportCsv',
                    },
                    {
                      name: (
                        <Permissions.CallToAction isPlan={!checkPlanPermissions('Export')}>
                          <FormattedMessage id={'exportExcel'} />
                        </Permissions.CallToAction>
                      ),
                      id: 'Export EXCEL',
                      trackingId: 'leads-filters-exportExcel',
                    },
                    {
                      name: (
                        <Permissions.CallToAction isPlan={!checkPlanPermissions('Export')}>
                          <FormattedMessage id={'integrations'} />
                        </Permissions.CallToAction>
                      ),
                      id: 'Integrations',
                      trackingId: 'leads-filters-Integrations',
                    },
                  ]}
                  withId={true}
                  onChange={handleExportLeads}
                  labelsStyle={{ left: 0, width: '100%' }}
                  selectedLabelStyle={{ width: '155px' }}
                  staticLabel={
                    selectedLeads() ? (
                      <FormattedMessage id={'leads.exportSelected'} />
                    ) : (
                      <FormattedMessage id={'leads.exportAll'} />
                    )
                  }
                  disabled={planIsLimitedPermission}
                  lock={!checkMemberPermissions('Export') && !checkPlanPermissions('Export')}
                  lockExportCrm={planIsLimitedPermission}
                />
              ) : (
                <SelectPicker
                  values={[
                    {
                      name: <FormattedMessage id={'exportCsv'} />,
                      id: 'Export CSV',
                      trackingId: 'leads-filters-exportCsv',
                    },
                    {
                      name: <FormattedMessage id={'exportExcel'} />,
                      id: 'Export Excel',
                      trackingId: 'leads-filters-exportExcel',
                    },
                    {
                      name: <FormattedMessage id={'integrations'} />,
                      id: 'Integrations',
                      trackingId: 'leads-filters-Integrations',
                    },
                  ]}
                  withId={true}
                  onChange={handleExportLeads}
                  labelsStyle={{ left: 0, width: '100%' }}
                  selectedLabelStyle={{ width: '155px' }}
                  staticLabel={
                    selectedLeads() ? (
                      <FormattedMessage id={'leads.exportSelected'} />
                    ) : (
                      <FormattedMessage id={'leads.exportAll'} />
                    )
                  }
                  disabled={planIsLimitedPermission}
                  lock={!checkMemberPermissions('Export') && !checkPlanPermissions('Export')}
                  lockExportCrm={planIsLimitedPermission}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {deleteLeadsToggle && (
        <ModalMessage
          toggle={deleteLeadsToggle}
          setToggle={() => {
            setDeleteLeadsModal(false);
          }}
          title={
            <>
              <FormattedMessage id={'delete'} /> {selectedLeads()}{' '}
              <FormattedMessage id={'lead(s)'} />
            </>
          }
          textElement={
            <>
              <strong>
                <FormattedMessage id={'leads.pleaseConfirmARemovalOf'} /> {selectedLeads()}{' '}
                <FormattedMessage id={'lead(s)'} />
              </strong>
            </>
          }
          action={intl.formatMessage({ id: 'confirm' })}
          handleAction={handleDeleteLeads}
          tracking={{
            confirm: 'leads.deleteLead-confirm',
            cancel: 'leads.deleteLead-cancel',
          }}
        />
      )}

      <ToastContainer position="bottom-right" style={{ top: 100 }} />
    </div>
  );
}

export default injectIntl(LeadsListFilter);
