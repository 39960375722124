import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { BillingContext } from '../../store/billing.store';
import { PreLoader, Tooltip } from '../../../../components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CheckoutFormWrapper, Field } from '../common/common.tools';
import OldSubscriptionInfo from './SubscriptionInfo/OldSubscriptionInfo';
import { OldExtraCreditsTable } from './ExtraCreditsTable/OldExtraCreditsTable';
import OldDiscountSection from './DiscountSection/OldDiscountSection';
import { CREDITS } from '../../../../constants';
import CurrencyDisplay from '../../../../components/CurrencyDisplay/CurrencyDisplay';
import OldBillingInfoSection from './BillingInfoSection/OldBillingInfoSection';

function OldOrderSummary({ plan, organizationCredits, blockProceed, error, user }: any) {
  const [couponModalToggle, setCouponModalToggle] = useState(false);
  if (!plan.credits) return <PreLoader />;
  const currentPlan = organizationCredits.plan;
  let checkCurrentExtraCredits = CREDITS.filter((credit) => credit !== 'legacyCredits').some(
    (credit) => currentPlan[credit] && !!currentPlan[credit].extra,
  );
  return (
    <CheckoutFormWrapper
      title={<FormattedMessage id={'billing.orderSummary'} />}
      style={{ marginTop: 30, marginBottom: 200 }}
    >
      <OldSubscriptionInfo
        plan={plan}
        currentPlan={currentPlan}
        user={user}
        organizationCredits={organizationCredits}
      />
      {(plan.extraCredits.length > 0 || checkCurrentExtraCredits) && (
        <OldExtraCreditsTable
          currentPlan={currentPlan}
          plan={plan}
          user={user}
          organizationCredits={organizationCredits}
        />
      )}
      {organizationCredits.plan.planId == '0' && (
        <OldDiscountSection
          couponModalToggle={couponModalToggle}
          setCouponModalToggle={setCouponModalToggle}
          user={user}
          organizationCredits={organizationCredits}
        />
      )}
      <OldBillingInfoSection
        plan={plan}
        currentPlan={currentPlan}
        user={user}
        organizationCredits={organizationCredits}
      />
      <hr />
      <div className="inline-info total-line main-line">
        <span className="key-txt">
          <FormattedMessage id={'billing.dueToday'} />
        </span>
        <span>
          <strong>
            <CurrencyDisplay
              user={user}
              organizationCredits={organizationCredits}
              amount={(plan.total * 0.01).toFixed(2)}
            />
          </strong>{' '}
        </span>
      </div>
    </CheckoutFormWrapper>
  );
}

export default injectIntl(OldOrderSummary);
