import React, { useEffect, useState } from 'react';

import CurrencyDisplay from '../../../../../../components/CurrencyDisplay/CurrencyDisplay';
import { getPlans } from '../../../../../../services/api/credits.api';
import './FixPriceInfoFreePlan.css';
import { useEvaluatedFlags } from '../../../../../../providers/LDProvider/LDProvider';
import useChristmasDiscount from '../../../../../../services/utils/hooks/useChristmasCoupon';
import NumberFormatter from '../../../../../../components/NumberFormatter/NumberFormatter';

export default function FixPriceInfoFreePlan({
  selectedPlan,
  addOnsPrice,
  organizationCredits,
  user,
  planType,
  nbLicenses,
  applyDiscount,
  style,
}: any) {
  const [plan, setPlan] = useState({});
  const [loading, setLoading] = useState(true);
  const flags: any = useEvaluatedFlags(['']);
  const { isEligibleForDiscount } = useChristmasDiscount(flags, organizationCredits);

  useEffect(() => {
    getPlans().then(({ data, statusCode }) => {
      if (statusCode === 200) {
        //@ts-expect-error
        const plans = data.plans.filter((plan: any) => plan.planId === selectedPlan.planId);
        setPlan(plans[0]);
      }
      setLoading(false);
    });
  }, [selectedPlan.planId]);

  const formatPrice = (price: any) => {
    const formattedPrice = price.toFixed(2);
    return formattedPrice.endsWith('.00') ? formattedPrice.slice(0, -3) : formattedPrice;
  };

  const calculateDiscountedPrice = (amount: number) => {
    if (applyDiscount && isEligibleForDiscount) {
      return amount * 0.7;
    }
    return amount;
  };

  if (loading) {
    return <div className="spinner"></div>;
  }

  if (selectedPlan.yearly && planType === 'yearly') {
    return (
      <div>
        <NumberFormatter
          value={
            applyDiscount
              ? Math.ceil(
                  formatPrice(
                    calculateDiscountedPrice(
                      selectedPlan.subscriptionTotal / (selectedPlan.remainingMonths || 12) / 100,
                    ),
                  ),
                )
              : formatPrice(
                  calculateDiscountedPrice(
                    selectedPlan.subscriptionTotal / (selectedPlan.remainingMonths || 12) / 100,
                  ),
                )
          }
          user={user}
          organizationCredits={organizationCredits}
          currency={true}
          decimals={true}
          style={style}
        />
      </div>
    );
  }

  if (!selectedPlan.yearly && planType === 'monthly') {
    return (
      <div>
        <NumberFormatter
          value={
            applyDiscount
              ? Math.ceil(
                  formatPrice(calculateDiscountedPrice(selectedPlan.subscriptionTotal / 100)),
                )
              : formatPrice(calculateDiscountedPrice(selectedPlan.subscriptionTotal / 100))
          }
          user={user}
          organizationCredits={organizationCredits}
          currency={true}
          decimals={true}
          style={style}
        />
      </div>
    );
  }

  let toDeduct;
  if (selectedPlan.yearly && planType === 'monthly') {
    if (organizationCredits.plan.numberOfMonths === 1) {
      toDeduct = organizationCredits.plan.numberOfSeats * organizationCredits.plan.pricePerSeat;
    }
    //@ts-expect-error
    const price = plan.pricePerSeat * nbLicenses - (toDeduct || 0);

    return (
      <div>
        <NumberFormatter
          value={
            applyDiscount
              ? Math.ceil(formatPrice(calculateDiscountedPrice(price)))
              : formatPrice(calculateDiscountedPrice(price))
          }
          user={user}
          organizationCredits={organizationCredits}
          currency={true}
          decimals={true}
          style={style}
        />
      </div>
    );
  }

  if (!selectedPlan.yearly && planType === 'yearly') {
    return (
      <div>
        <NumberFormatter
          value={
            applyDiscount
              ? Math.ceil(
                  //@ts-expect-error
                  formatPrice(calculateDiscountedPrice(plan.yearlyPricePerSeat * nbLicenses)),
                )
              : //@ts-expect-error
                formatPrice(calculateDiscountedPrice(plan.yearlyPricePerSeat * nbLicenses))
          }
          user={user}
          organizationCredits={organizationCredits}
          currency={true}
          decimals={true}
          style={style}
        />
      </div>
    );
  }

  return <div>NaN</div>;
}
